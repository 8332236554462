<template>
  <van-nav-bar
      class="van-nav-bar"
      :title="goback_title"
      left-text="返回"
      left-arrow
      right-text="刷新"
      @click-left="onClickLeft"
      @click-right="onClickRight"
  />
</template>

<script>

export default {
  props: ['goback_title'],
  data() {
    return {
      clicked: false,
    };
  },
  mounted(){

  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onClickRight() {

      if (this.clicked) {
        return;
      }

      this.clicked = true;
      this.$router.go(0);
    }
  },
};
</script>

<style lang="less" scoped>
::v-deep .van-nav-bar__title {
  font-size: 14px !important;
}
</style>