<template>
  <div>
    <Goback goback_title="报名详情" />
    <div class="navTab_three">
      <div class="navTab_three_list">
        <div class="navTab_three_list_flex">
          <div>会员姓名：</div>
          <div>{{item.user.real_name}}</div>
        </div>
        <div class="navTab_three_list_flex">
          <div>身份证：</div>
          <div>{{item.user.id_number}}</div>
        </div>
        <div class="navTab_three_list_flex">
          <div>手机号：</div>
          <div>{{item.user.phone}}</div>
        </div>
        <div class="navTab_three_list_flex">
          <div>报名时间：</div>
          <div>{{item.created_at}}</div>
        </div>
        <div class="navTab_three_list_flex">
          <div>入职日期：</div>
          <div>{{item.entry_time}}</div>
        </div>
        <div class="navTab_three_list_flex">
          <div>离职日期：</div>
          <div>{{item.quit_time}}</div>
        </div>
        <div class="navTab_three_list_flex">
          <div>企业简称：</div>
          <div>{{item.abbreviation}}</div>
        </div>
        <div class="navTab_three_list_flex">
          <div>职位名称：</div>
          <div>{{item.job_title}}</div>
        </div>
        <div class="navTab_three_list_flex">
          <div>职位编号：</div>
          <div>{{item.position_number}}</div>
        </div>
        <div class="navTab_three_list_flex">
          <div>报名状态：</div>
          <div>{{item.audit_status}}</div>
        </div>
        <div class="navTab_three_list_flex">
          <div>工价详情：</div>
          <div style="color:#1F97EE" @click="handleSee(item.wages_describe)">查看</div>
        </div>
        <!-- <div class="navTab_three_list_flex">
          <div>收单详情：</div>
          <div style="color:#1F97EE" bindtap="handleSee(item.provide_price)">查看</div>
        </div> -->
        <div class="navTab_three_list_flex">
          <div>恶意报名：</div>
          <div>{{item.is_bad}}</div>
        </div>

        <!-- 协议合同 -->
        <div class="line"> </div>
        <div class="navTab_three_list_flex">
          <div>合同分类：</div>
          <div><van-tag type="success">协议合同</van-tag></div>
        </div>
        <div class="navTab_three_list_flex">
          <div>合同类型：</div>
          <div v-if="item.sign_type == 1">电子合同</div>
          <div v-if="item.sign_type == 2">纸质合同</div>
          <div v-if="item.sign_type == 3">不需要</div>
        </div>
        <div class="navTab_three_list_flex">
          <div>合同文件：</div>
          <div v-if="item.applets_contract.contract_type_1">
            <div>
    <!--          <image @click="handleImg(item.applets_contract.contract_type_1.img_url, img)" class="appletsContractFile" v-if="item.applets_contract.img_url" :key="index" v-for="(item, index) in item.applets_contract.contract_type_1.img_url" v-for-item="img" src="{{img}}" mode="aspectFit" />-->
            </div>
            <div v-if="!item.contract_type_2.contract_type_1.img_url"></div>
          </div>
          <div v-if="!item.applets_contract.contract_type_1"></div>
        </div>
        <div class="navTab_three_list_flex">
          <div>合同状态：</div>
          <!-- <div v-if="item.applets_contract">
            <div v-if="item.applets_contract.contract_type_1.status == 1"><van-tag type="success">审核通过</van-tag></div>
            <div v-if="item.applets_contract.contract_type_1.status == 2"><van-tag type="warning">等待审核</van-tag></div>
            <div v-if="item.applets_contract.contract_type_1.status == 3"><van-tag type="danger">审核驳回</van-tag></div>
          </div> -->
          <div v-if="item.is_unneed_sign">
            <div v-if="item.applets_contract.contract_type_1">
              <div><van-tag type="success">已提交</van-tag></div>
            </div>
            <div v-if="!item.applets_contract.contract_type_1">未提交</div>
          </div>
          <div v-if="!item.is_unneed_sign">旧数据不需提交</div>
        </div>


        <!-- 劳动合同 -->
        <div class="line"> </div>
        <div class="navTab_three_list_flex">
          <div>合同分类：</div>
          <div><van-tag type="success">劳动合同</van-tag></div>
        </div>
        <div class="navTab_three_list_flex">
          <div>合同类型：</div>
          <div v-if="item.sign_agger_type == 1">电子合同</div>
          <div v-if="item.sign_agger_type == 2">纸质合同</div>
          <div v-if="item.sign_agger_type == 3">不需要</div>
        </div>
        <div class="navTab_three_list_flex">
          <div>合同文件：</div>
          <div v-if="item.applets_contract.contract_type_2">
            <div>
    <!--          <image bindtap="handleImg" data-urls="{{item.applets_contract.contract_type_2.img_url}}" data-current="{{img}}" class="appletsContractFile" v-if="item.applets_contract.img_url" v-key="key" v-for="{{item.applets_contract.contract_type_2.img_url}}" v-for-item="img" src="{{img}}" mode="aspectFit" />-->
            </div>
            <div v-if="!item.contract_type_2.contract_type_2.img_url"></div>
          </div>
          <div v-if="!item.applets_contract.contract_type_2"></div>
        </div>
        <div class="navTab_three_list_flex">
          <div>合同状态：</div>
          <!-- <div v-if="item.applets_contract">
            <div v-if="item.applets_contract.contract_type_2.status == 1"><van-tag type="success">审核通过</van-tag></div>
            <div v-if="item.applets_contract.contract_type_2.status == 2"><van-tag type="warning">等待审核</van-tag></div>
            <div v-if="item.applets_contract.contract_type_2.status == 3"><van-tag type="danger">审核驳回</van-tag></div>
          </div> -->
          <div v-if="item.is_unneed_sign">
            <div v-if="item.applets_contract.contract_type_2">
              <div><van-tag type="success">已提交</van-tag></div>
            </div>
            <div v-if="!item.applets_contract.contract_type_2">未提交</div>
          </div>
          <div v-if="!item.is_unneed_sign">旧数据不需提交</div>
              </div>
            </div>
    </div>

    <van-popup class="showContent" v-model="showContent" closeable>
      <div class="showContent_signItem" v-html="signItem"></div>
    </van-popup>
  </div>
</template>

<script>
 import {
   getApplets
 } from '../../api/test'
import Goback from "@/components/home/goback.vue";
export default {
  components: {Goback},
  data () {
    return {
      item: {
        user: {},
        applets_contract: {},
      },
      showContent: false,
      signItem: '',
      file_id:''
      // goback_title: '报名详情',
    }
  },
  created(){
    console.log(this.$route.params.strItem,'..this.$route.params')
    this.file_id = this.$route.params.strItem;
  },
  mounted () {
    this.getShow();
  },
  methods: {
    async getShow () {
      let res = await getApplets({
        applets_id: this.file_id
      });

      this.item = res.data;
      if (!this.item.applets_contract) {
        this.item.applets_contract = {};
      }
    },
    //报名记录收单查看
    handleSee(content) {
      this.signItem = content;
      this.showContent = true;
    },
    onClickLeft() {
      this.$router.go(-1);
    }
  },
}
</script>

<style lang="less" scoped>
/* pages/memeberRecord/memeberRecord.wxss */
page {
  background: #f5f5f5;
}

.memeberRecord_index {
  position: sticky;
  top: 0;
  z-index: 100;
}

.public_visit_bottom {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.van-tabs__line {
  background-color: #389EEE !important;
}

.public_visit {
  padding: 0 0 20px;
  background: #ffffff;
  position: sticky;
  top: 0;
  z-index: 100;
}



.public_index {
  padding: 30px;
}


.van-tab {
  /*font-size: 26px;*/
  line-height: 76px;
}

.van-tabs--line .van-tabs__wrap {
  height: 80px;
}

.showPut {
  width: 600px;
  padding: 30px 0;
}

.showPut_title {
  border-bottom: 1px solid #F6F6F6;
  padding: 10px 30px 30px;
  max-height: 220px;
  overflow-y: auto;
}

.showPut_title>div {
  display: inline-block;
  background: #F4F4F4;
  font-size: 28px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  padding: 10px 15px;
  margin: 0 20px 20px 0;
  border-radius: 10px;
}

.showPut_title_active {
  background: #DFF2FF !important;
  color: #1F97EE !important;
}

.van-popup {
  border-radius: 12px;
}

.showPut textarea {
  width: 90%;
  height: 150px;
  background-color: #F6F6F6;
  /*font-size: 26px;*/
  padding: 10px;
  box-sizing: border-box;
  margin: 30px auto;
}

.showPut_button {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 30px;
}

.showPut_button div {
  color: #ffffff;
  /*font-size: 26px;*/
  background-color: #389EEE;
  padding: 10px 30px;
  width: 170px;
  border-radius: 30px;
  text-align: center;
}

.showPut_button div:first-child {
  background-color: #96CCF6;
}



.myMember_source_tab {
  display: flex;
  align-items: center;
  background: #ffffff;
  padding: 20px 20px 30px 20px;
  justify-content: space-around;
  flex-wrap: wrap;
}

.tab_top {
  border-top: 1px solid #F5F5F5;
  margin-bottom: 20px;
  justify-content: start;
}
.tab_top>div{
  flex: none!important;
  margin-right: 20px;
}

.tabActive {
  color: #1F97EE !important;
  position: relative;
}

.tabActive::after {
  content: '';
  position: absolute;
  bottom: -34px;
  left: 0;
  width: 100%;
  height: 6px;
  border-radius: 12px;
  background: #1F97EE;
}

.myMember_source_tab>div {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  font-size: 24px;
  /* width: 16%; */
  flex: 1;
  text-align: center;
}

/* 基本信息 ----------------------------------------*/
.navTab_one {
  background: #ffffff;
  padding: 30px;
}

.name_icon {
  background: linear-gradient(#3FA6F0, #7BC0F4);
  color: #ffffff;
  display: inline-block;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin-right: 10px;
}

.navTab_one_list {
  display: flex;
  align-items: center;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  font-size: 28px;
  border-bottom: 1px solid #F5F5F5;
  padding: 30px 0;
}

.navTab_one_list>div:first-child {
  width: 100px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  font-size: 28px;
}

.navTab_one_list>div:last-child {
  flex: 1;
}

/* 基本信息----------------------- */

/* 报名记录-------------------------- */
.navTab_two {
  padding: 30px;
  background: #ffffff;
}

.navTab_two_tab {
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  border-bottom: 1px solid #F5F5F5;
  margin-bottom: 30px;
}

.navTab_two_tab>div {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  font-size: 24px;
  width: 20%;
  text-align: center;
}

.navTab_two_list {
  display: flex;
  /* align-items: center; */
  border-bottom: 1px solid #F5F5F5;
  padding: 30px 0;
}

.navTab_two_list>div {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  font-size: 24px;
  width: 20%;
  text-align: center;
}

/* 报名记录----------------------------------------------------------- */

/* 在离职记录-------------------------------------------------------------- */
.navTab_three_list {
  background: #ffffff;
  padding: 20px 30px;
  line-height: 30px;
  margin-bottom: 20px;
}

.navTab_three_list_flex {
  display: flex;
  align-items: center;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  /*font-size: 26px;*/
}

.navTab_three_list_flex>div:first-child {
  width: 80px;
}

.navTab_three_list_flex>div:last-child {
  flex: 1;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  /*font-size: 26px;*/
}

/* 在离职记录-------------------------------------------------------------- */


/* 打卡记录-------------------------------------------------------------- */
.navTab_four {
  background: #ffffff;
  padding: 30px;
}

.navTab_four_tab {
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  border-bottom: 1px solid #F5F5F5;
  margin-bottom: 30px;
}

.navTab_four_tab>div {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  font-size: 24px;
  width: 20%;
  text-align: center;
}

.navTab_four_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
  border-bottom: 1px solid #F5F5F5;
}

.navTab_four_list>div {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  font-size: 24px;
  width: 21%;
}

.navTab_four_list_day {
  background: #E9F5FF;
  padding: 5px 10px;
  border-radius: 10px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #1F97EE;
  font-size: 24px;
  margin-left: 25px;
  text-align: center
}

.navTab_four_list_status {
  background: #E9F5FF;
  padding: 5px 10px;
  border-radius: 10px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #1F97EE;
  font-size: 24px;
  margin-left: 20px;
  text-align: center;
  margin-right: 20px;
}

/* 打卡记录------------------------------------------------------- */

/* 回访记录------------------------------------------------------- */
.navTab_five_tab {
  display: flex;
  align-items: center;
  background: #ffffff;
  padding: 30px;
  justify-content: space-around;
}

.navTab_five_tab>div {
  background: #F5F5F5;
  font-size: 30px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  width: 230px;
  height: 90px;
  text-align: center;
  line-height: 90px;
  border-radius: 20px;
}

.historyActive {
  background: #E9F5FF !important;
  color: #1F97EE !important;
}

.public_visit_flex {
  display: flex;
  align-items: center;
  font-size: 30px;
  height: 60px;
  background: #ffffff;
  padding: 10px 30px;
  margin-top: 20px;
  color: #999999;
  font-weight: 400;
  margin-bottom: 20px;
}

.public_visit_div {
  text-align: center;
  font-size: 30px;
  background: #DCF0FF;
  color: #389EEE;
  padding: 8px 30px;
  border-radius: 20px;
  width: 70px;
  border: 1px solid #97CFF7;
}

.public_index_main {
  background: #ffffff;
  margin-bottom: 20px;
  padding: 30px;
}

.public_index_main_list {
  display: flex;
  font-size: 28px;
  line-height: 48px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.public_index_main_list>div:first-child {
  width: 150px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.public_index_main_list>div:last-child {
  flex: 1;
}

.public_index_main_list_time {
  display: flex;
  font-size: 28px;
  line-height: 48px;
  color: #999999;
}

.public_index_main_list_time>div:first-child {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  /*font-size: 26px;*/
}

.public_index_main_list_width>div:first-child {
  margin-right: 20px;
}

/* 回访记录------------------------------------------------ */

/* 发薪记录------------------------------------------------ */
.navTab_six {
  margin-top: 20px;
}

.navTab_six_list {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  /*font-size: 26px;*/
  padding: 30px;
  background: #ffffff;
  line-height: 55px;
  margin-bottom: 20px;
}

.navTab_six_list_flex>div:last-child {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  /*font-size: 26px;*/
}

.navTab_six_list_flex {
  display: flex;
}

.navTab_six_list_flex>div:first-child {
  width: 140px;
}

.navTab_six_list_flex_title {
  flex: 1;
}

.navTab_six_list_flex_son {
  display: flex;
  align-items: center;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  /*font-size: 26px;*/
}

.navTab_six_list_flex_son>div:last-child {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  /*font-size: 26px;*/
  flex: 1;
}

/* 发薪记录------------------------------------------------ */


/* 工资条------------------------------------------------ */

.paySilp {
  width: 100%;
  position: relative;
}

.paySilp_name {
  position: absolute;
  top: 130px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  white-space: nowrap;
}

.paySilp_price {
  position: absolute;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
}

.paySilp_price div:first-child {
  font-size: 56px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #FFFFFF;
  letter-spacing: 3px;
}

.paySilp_price div:last-child {
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  opacity: .9;
  margin-top: 10px;
}

.paySilp_basic div:last-child {
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #222222;
  margin-left: auto;
}

.month {
  text-align: center;
  font-size: 30px;
  margin: 20px 0;
}

.paySilp_day {
  width: 90%;
  margin: 30px auto;
  background-color: #FFFFFF;
  border-radius: 6px;
  padding: 20px 30px;
  box-sizing: border-box;
}

.paySilp_day_title {
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #7F7F7F;
  position: relative;
  width: 92px;
  margin-bottom: 20px;
}

.paySilp_day_title::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 26px;
  height: 4px;
  background: #389EEE;
  border-radius: 4px;
}

.paySilp_day_list {
  display: flex;
  align-items: center;
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #090909;
  padding: 20px 0;
  border-bottom: 1px solid #F2F2F2;
}

.paySilp_day_list:last-child {
  border-bottom: 0;
}

.paySilp_day_list div:last-child {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #222222;
  margin-left: auto;
}

.payImg {
  text-align: center;
  font-size: 32px;
  background: #E0C169;
  height: 100%;
  padding-top: 150px;
  box-sizing: border-box;
  color: #FFFFFF;
}

.payImg image {
  width: 250px;
  height: 230px;
  display: block;
  margin: 0px auto 10px;
}

page,
.div {
  height: 100%;
}

.picker {
  font-size: 30px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.picker_play {
  transform: rotate(90deg);
  margin-left: 5px;
}

/* 工资条------------------------------------------------ */



/* 银行卡信息------------------------------------------------ */
.navTab_seven_list {
  background: #ffffff;
  padding: 30px;
  line-height: 58px;
  margin-bottom: 20px;
}

.navTab_seven_list_flex {
  display: flex;
  align-items: center;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  /*font-size: 26px;*/
}

.navTab_seven_list_flex>div:first-child {
  width: 220px;
}

.navTab_seven_list_flex>div:last-child {
  flex: 1;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  /*font-size: 26px;*/
}

/* 银行卡信息-------------------------------------------------------- */

/* 异常记录-------------------------------------------------------- */
.navTab_eight_flex {
  display: flex;
  align-items: center;
  font-size: 28px;
  background: #ffffff;
  padding: 10px 30px;
  margin-top: 20px;
  color: #999999;
  font-weight: 400;
  margin-bottom: 20px;
}

.navTab_eight_div {
  text-align: center;
  font-size: 28px;
  background: #E9F5FF;
  color: #389EEE;
  padding: 15px 20px;
  border-radius: 20px;
  margin-left: auto;
  display: flex;
  align-items: center;
}

.navTab_eight_div .van-icon-plus {
  margin-right: 10px;
}

.navTab_eight_title {
  display: flex;
  align-items: center;
}

.navTab_eight_status {
  margin-left: auto;
  color: #FF913D;
}

.navTab_eight_over {
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
}

/* 异常记录-------------------------------------------------------- */

/* 住宿记录-------------------------------------------------------- */

.navTab_nine {
  padding: 30px;
  background: #ffffff;
}

.navTab_nine_tab {
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  border-bottom: 1px solid #F5F5F5;
  margin-bottom: 30px;
}

.navTab_nine_tab>div {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  font-size: 24px;
  width: 20%;
  text-align: center;
}

.navTab_nine_list {
  display: flex;
  padding: 30px 0;
  border-bottom: 1px solid #F5F5F5;
}

.navTab_nine_list>div {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  font-size: 24px;
  width: 20%;
  text-align: center;
}

/* 住宿记录-------------------------------------------------------- */

/* 订单查看记录-------------------------------------------------------- */
.navTab_ten {
  margin-top: 20px;
}

.navTab_ten_time {
  background: #ffffff;
  padding: 30px;
}

.data_week_btn_time {
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #333333;
  font-size: 32px;
  text-align: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #F5F5F5;
  padding-bottom: 30px;
}

.navTab_ten_main {
  background: #ffffff;
  padding: 30px;
  margin-top: 20px;
}

.navTab_ten_tab {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #F5F5F5;
  padding-bottom: 30px;
}

.navTab_ten_tab>div {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  font-size: 24px;
}

.navTab_ten_list {
  display: flex;
  align-items: center;
  padding: 30px 0;
  border-bottom: 1px solid #F5F5F5;
}

.navTab_ten_list>div {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  font-size: 24px;
}

.navTab_ten_time .index {
  background: #ffffff;
}

/* 订单查看记录-------------------------------------------------------- */
.memeberPhone {
  position: fixed;
  bottom: 300px;
  right: 30px;
  width: 100px;
  height: 100px;
  z-index: 100;
}

.memeberPhone image {
  width: 100%;
  height: 100%;
}

.showContent {
  padding: 10px;
  width: 400px;
  height: 300px;
}

.showContent_signItem {
  height: 500px;
  overflow-y: auto;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  //font-size: 16px;
  line-height: 30px;
}

.showContent_time {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  font-size: 16px;
  line-height: 30px;
}

.showContent_time>div>text {
  color: #333333;
}

.navTab_five_tab_choose {
  text-align: center;
  font-size: 30px;
  background: #ffece9;
  color: #FF4E3A;
  padding: 8px 30px;
  border-radius: 20px;
  margin-left: auto;
  width: 70px;
  border: 1px solid #FF4E3A;
  margin-right: 20px;
}

.showPut_no_title {
  padding: 0 0 20px 0;
  border-bottom: 1px solid #f5f5f5;
  font-size: 32px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  text-align: center;
}

.showPut_no_tip {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FF4E3A;
  padding: 15px;
}

.showPut_no_tip>div {
  flex: 1;
}

.appletsContractFile{
  border: 1px solid #EFEFEF;
  border-radius: 2px;
  width: 50px;
  height: 50px;
  margin: auto 2px;
}

.line{
  border-top: 1px dotted #CCC;
  margin: 20px;
}
</style>



